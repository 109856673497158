<template>
  <div class="score">
    <div class="score-title vertical-center">
      <div class="score-title-l"></div>
      学习评价
    </div>
    <div class="van-hairline--bottom"></div>
    <div class="star vertical-center">
      <span style="color:red;">*</span>
      评分
      <van-rate
      v-model="starnum"
      size="1.2826666667rem"
      color="#ffd21e"
      void-icon="star"
      void-color="#eee"
      class="star-item"
    />
    </div>
    <van-field rows="6"  type="textarea" autosize v-model="content" placeholder="请输入您的评价～" maxlength="100" show-word-limit />
    <van-checkbox v-model="isAnonymous">匿名评价</van-checkbox>
    <div class="sub align-center" @click="evaluate()">提交</div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "score",
  data() {
    return {
      starnum:0,
      content:'',
      isAnonymous:false,
      model_id:''
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    evaluate: _.debounce(function () {
      if (this.starnum == 0 ){
        this.$toast('请选择评分')
        return false
      }
      let url = this.$api.evaluate;
      let data ={
        model_id:this.$route.query.id,
        content:this.content,
        star:this.starnum,
        is_anonymous:this.isAnonymous
      }
      this.$http.post(url,data,true).then( res =>{
        if(res.data.success){
          this.$router.replace("/user/group")
        }
      })
    }, 500),
  },
  watch: {
    userInfo(val, oldVal) {
      return val;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  components: {
  },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/group/score.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;
.van-cell{
  padding: unset;
}
.van-field__control{
  border-radius: 10/$r;
  background: #F7F7F7;
  padding: 20/$r;
}
.van-checkbox__label{
  font-size: 24/$r;
  font-family: PingFang SC;
  font-weight: bold;
  color: #999999;
}
.van-checkbox__icon .van-icon{
  width: 28/$r;
  height: 28/$r;
  font-size: 20/$r;
}
.van-checkbox__icon--checked .van-icon {
  color: #fff;
  background-color: #FF6B29;
  border-color: #FF6B29;
}
.van-checkbox{
  display: flex;
  align-items: center;
  margin-top: 20/$r;
}
.van-checkbox__icon{
  display: flex;
  align-items: center;
}
</style>